import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import ImproveYourLife from '../components/ImproveYourLife';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Conquer Fear" />

    <div>
      <div className="section image-bg-section">
        <div className="container">
          <div className="content-width-medium">
            <h6 className="subheading">Conquer Fear</h6>
            <h1 className="no-bottom-space center-mobile">Don’t let fears hold you back</h1><Link to="/pricing/" className="button margin-top w-inline-block">
              <div>Get started</div>
            </Link>
          </div>
        </div>
        <div style={{backgroundImage: 'url("/images//5ddc25a4578bab5d69305d97_dive_t20_do4m19.jpg")'}} className="image-background bg-image-1" />
        <div className="background-scrim-left" />
      </div>
      <div className="section">
        <div className="container">
          <div className="w-richtext">
            <p>Most fears are irrational and result from childhood programming. Nevertheless, these fears are powerful and can rob you of much enjoyment and success.</p>
            <p>The WiseGuide App is designed to help you quickly eliminate worry, overcome your fears, and live anxiety-free. We will guide you in reprogramming your inner mind. You will turn your subconscious thoughts from a foe into an ally. You will condition your mind to reject negative, pessimistic thinking and replace it with the kind of thoughts that will make you excited to face each new day and take on new challenges with confidence.</p>
            <p>Start a FREE 7-day trial to experience how The WiseGuide App can help you overcome your fears. Just listen.</p>
          </div>
        </div>
      </div>
    </div>

    <ImproveYourLife />

    <DownloadApp />
  </Layout>
);
