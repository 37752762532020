import React from "react"
import { Link } from "gatsby"

export default ({ ...props }) => (
  <div className="section bg-gray-4" {...props}>
    <div className="container">
      <div className="section-title">
        <h3 className="h2">It's never been easier to improve your life</h3>
        <div>If you're ready to take the next step to improve your life then follow the steps below. We'll see you on the other side! </div>
      </div>
      <div className="w-layout-grid grid-thirds">
        <div data-w-id="ff05d9b7-cba0-cbbb-23ae-f3269c6ea982" className="centered-grid-item">
          <div className="circle bg-primary-2">
            <div className="circle-number text-white">1</div>
          </div>
          <div>
            <h6>Choose how to start</h6>
            <p>Click <Link to="/pricing/">Pricing</Link> and choose your plan. Whether you choose the free version or a subscription, it’s FREE to start.</p>
          </div>
        </div>
        <div data-w-id="ff05d9b7-cba0-cbbb-23ae-f3269c6ea98e" className="centered-grid-item">
          <div className="circle bg-primary-2">
            <div className="circle-number text-white">2</div>
          </div>
          <div>
            <h6>Download the app</h6>
            <p>However you choose to start, you'll be directed to download our app in the app store.</p>
          </div>
        </div>
        <div data-w-id="ff05d9b7-cba0-cbbb-23ae-f3269c6ea997" className="centered-grid-item">
          <div className="circle bg-primary-2">
            <div className="circle-number text-white">3</div>
          </div>
          <div>
            <h6>Start improving your life</h6>
            <p>Find the titles you want and simply start listening! It’s that easy. The app will help you break bad habits, conquer stress, overcome fear, or improve any other aspect of your life!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);